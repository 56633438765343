var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"fill-height mt-n3 mx-n4"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":"","show-arrows":""}},[_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-timesheets-user-reports'),
                params: {
                    project: _vm.project,
                },
            }}},[_vm._v(" USER REPORTS ")]),_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-timesheets-daily-reports'),
                params: {
                    project: _vm.project,
                },
            }}},[_vm._v(" DAILY REPORTS ")]),_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-timesheets-weekly-reports'),
                params: {
                    project: _vm.project,
                },
            }}},[_vm._v(" WEEKLY REPORTS ")]),_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-weekly-timekeeping-reports'),
                params: {
                    project: _vm.project,
                },
            }}},[_vm._v(" TIMESHEETS ")]),_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-timesheets-settings'),
                params: {
                    project: _vm.project,
                },
            }}},[_vm._v(" SETTINGS ")])],1),_c('v-card',[_c('v-card-text',{staticClass:"overflow-y-auto",style:(`height: ${_vm.height}px;`)},[_c('router-view')],1)],1),_c('Errors')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }